 /* Define common styles */
 @import 'animate.css';

 @font-face {
  font-family: 'MyFont';
  src: url('./assets/fonts/itc-avant-garde-gothic-std/ITCAvantGardeStd-BkCnObl.otf') format('truetype');
}



.font-sm {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.head li a{
  cursor: pointer;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: rgba(10, 37, 64, 1);
}

/* Define styles for home topbar */
.home-topbar {
  background-color: rgba(99, 91, 255, 1);
  min-height: 515px;
  height: auto;

  .topbar-text {
    font-family: "IBM Plex Sans Arabic";
    font-weight: 700;
    font-size: 60px;
    line-height: 72.96px;
    color: rgba(250, 250, 250, 1);
  }
}

/* Define button styles */
.button-white{
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(10, 37, 64, 1);
  display: inline-block;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: rgba(10, 37, 64, 1);
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

/* Define logo size */
.logo-size {
  width: 105px;
  height: 24px;
}

.mob-pos{
  position: relative;
  top: 145px;
}


.section-heading{
  font-family: 'IBM Plex Sans Arabic';  // , sans-serif
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: rgba(10, 37, 64, 1);
}

.feature-shorttxt {
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(60, 96, 133, 1);
}

.sub-headings {
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: rgba(10, 37, 64, 1);
}

.text-styl {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 400;
  font-size: 14.5px;
  line-height: 24px;
  color: rgb(10, 37, 64);
}

.btn-lightblue {
  width: 166px;
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(99, 91, 255, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}
.btn-lightblue:hover{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  background-color: rgb(149 145 237);
  color: rgba(255, 255, 255, 1);
  border: none;
}
.register-w{
  width: 208px;
}

.gif-size{
  height: 124px;
  width: 124px;
}

.go-home{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(10, 37, 64, 1);
  text-decoration: none;
}

.offer-main{
  background: url(assets/images/offer-bg.png) no-repeat;
  background-size: cover;


  .offer-inner{
    // background: url(assets/images/Art.png) no-repeat;
    // padding: 40px;
    background-position: right;
  }
}

.blue-head{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  color: rgba(99, 91, 255, 1);
}
.border-l{
  border-left: 2px solid rgba(161, 157, 255, 1);
  padding-left: 12px;
}


.blog-writer{
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(10, 37, 64, 1);
}


.news-letter{
  background: rgba(10, 37, 64, 1);
  .info-txt{
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(250, 250, 250, 1);
  }

  .news-head{
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
    color: rgba(255, 255, 255, 1);
  }

  .email-field{
    height: 48px;
    border-radius: 8px;
    background-color: rgba(96, 126, 156, 1);
    border: none;
    padding-left: 6px;
    width: 320px;
  }

  .email-field::placeholder{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
  }
  .subscribe-button{
    width: 200px;
    height: fit-content; 
    border-radius: 8px;
    border: 1px solid rgba(10, 37, 64, 1); 
    padding: 12px 16px; 
    margin-bottom: 8px; 
    background-color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(10, 37, 64, 1);
  }
}


input:focus {
  outline: none;
  box-shadow: none!important;
}

.bg-lightgray{
  background:rgba(246, 248, 250, 1);
}


.register-txt{
  z-index: 100;
        padding-top: 40px;
        position: relative;
        left: 200px;
}

.banner-text{
 
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 400;
    font-size: 46px;
    line-height: 56px;
    color: rgba(10, 37, 64, 1);
 
}

.banner-100{
 
    font-family: 'IBM Plex Sans Arabic';
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    color: rgba(10, 37, 64, 1);
}

.banner-Ex{
 
  font-family: 'IBM Plex Sans Arabic';
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
   color: rgba(99, 91, 255, 1);

}




.over-flowx-auto{
  overflow-x: auto;
}

.border-pos{
  position: relative;
  top: 90px;
  z-index: 1;
}


.z-index100{
  z-index: 100;
}

.img-slider img{
  height: 68px;
}

.border-dashed{
   border: 1px dashed rgba(60, 96, 133, 1) !important
}


.drop-shadow-styl{
    box-shadow: -4px 0 16px 0 rgba(99, 91, 255, 0.15);
}

.h-100vh{
  height: 100vh;
}

.form-bg{
  background: rgba(255, 255, 255, 1);
  box-shadow: -4px 0 16px 0 rgba(99, 91, 255, 0.15);
  border-radius: 32px 0 0 32px;
}


.forms-heading {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 400;
  font-size: 46px;
  line-height: 56px;
  color: rgba(60, 96, 133, 1);
}

// .form-imgsize{
//   // min-width: 560.57px;
//   height: 427.29px;
// }

.d-flex-sp{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.form-logopos{
  position: relative;
  top: 20px;
}

.d-flex-poster{
  display: flex;
}


.forms-bg{
  background:#EFEFFF;height: 100%;
}


.container-form{
  padding-left: 106px;padding-right: 106px;
}

.pos-head-inner{
  position: relative;top: 20px;
}







.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
     border: none !important;
   border-radius: none !important;
    transition: var(--bs-navbar-toggler-transition);
    box-shadow: none;
    border: none;
}
html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: 'IBM Plex Sans Arabic';
  background-color: #fff !important;
}



.star-styl{
   color: rgba(246, 118, 95, 1);
   font-size: 16px;
}





mat-form-field {
  display: flex;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  justify-content: center;
  align-items:center;

}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0rem;
}

::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0rem;
}

::ng-deep .mat-form-field-underline {
 display: none;
}
::ng-deep .mat-select-arrow-wrapper{
  display: flex;
  justify-content: space-between;
}
::ng-deep .mat-form-field-infix{
  border: 0px;
  font-size: 1rem;
}
input:focus-visible {
  border: none !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color:  transparent !important;
  min-height: 68px !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled):focus {
  background-color:  transparent !important;
  min-height: 68px !important;
}
 

.mat-mdc-text-field-wrapper {
  border-bottom: none !important; 
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
  border-bottom: none !important;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  padding: 0px !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-disabled, ::ng-deep .mat-form-field-outline {
  opacity: 0 !important;
  }
  .mat-form-field.mat-focused .mat-form-field-underline {
    border-color: transparent !important;
  }
.sticky-nav{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
 



// media queries for mobile


@media only screen and (max-width: 600px) {
  .register-txt{
    z-index: 100;
          padding-top: 40px;
          position: relative;
          left: 0px;
  }

  .mob-pos{
    position: relative;
    top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  .border-dashed{
    border: 0px dashed rgba(60, 96, 133, 1) !important
 }


 .d-flex-poster{
  display: block;
}
.headtxt-main{
  padding-top: 3rem;
}

 

.mob-mt-5{
  margin-top: 40px;
}
.mob-pt-5{
  padding-top: 45px;
}
.home-topbar .topbar-text {
  font-weight: 700;
  font-size: 42px;
  line-height: 72.96px;
  color: rgb(250, 250, 250);
}

.container-form {
  padding-left: 0px;
  padding-right: 0px;
}
.pos-head-inner{
  position: relative;top:0px;
}
.mob-w-100{
  width: 100%;
}

.form-bg{
  background: rgba(255, 255, 255, 1);
  box-shadow: -4px 0 16px 0 rgba(99, 91, 255, 0.15);
  border-radius: 32px 32px 0px 0px;
}

.mob-mb-3{
  margin-bottom: 12px;
}

.mob-pl-5{
  padding-left: 60px !important;
}

}

